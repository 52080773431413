import React from 'react';
import styled from 'styled-components'

const LoadingWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Loading = (props) => {
  return (
    <LoadingWrapper>
      {props.pastDelay ? 'Loading...' : null}
    </LoadingWrapper>
  )
};

export default Loading;
