// import 'semantic-ui-css/semantic.min.css';
import Loadable from "react-loadable";

import { loading } from "./../../helpers/loading";

const LazyView = Loadable({
  loader: () => import("./lazy-index.js"),
  loading
});

export default LazyView;
