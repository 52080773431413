import { INBOX_URL } from "./routes";

export const DEFAULT_PRIVATE_VIEW = INBOX_URL;
export const REDIRECT_AFTER_LOGIN_LOCALSTORAGE_KEY = "redirectAfterLogin";
export const GRAPHQL_API_URL_DEVELOPMENT = "http://localhost:8000/-/graphql/";
export const GRAPHQL_API_URL_PRODUCTION = "https://www.flenzies.com/-/graphql/";

export const MAX_REQUEST_TITLE_LENGTH = 75;
export const MAX_REQUEST_BODY_LENGTH = 500;

export const PAYMENT_ENABLED = true;

export const LINKEDIN_REDIRECT_URI_DEVELOPMENT = "http://localhost:3000";
export const LINKEDIN_REDIRECT_URI_PRODUCTION = "https://flenzies.com";

export const QUESTION_TYPES = [
  "question",
  "opportunity",
  "challenge",
  "anonymous-question",
];

export const MIN_PASSWORD_SCORE = 2;

export const JWT_TOKEN_KEY = "JWT_TOKEN";
export const REFRESH_TOKEN_KEY = "REFRESH_TOKEN";
