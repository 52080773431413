import jwt_decode from "jwt-decode";
import zxcvbn from "zxcvbn";

import {
  DEFAULT_PRIVATE_VIEW,
  REDIRECT_AFTER_LOGIN_LOCALSTORAGE_KEY,
  MIN_PASSWORD_SCORE
} from "./../constants";

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  const stillValid = isTokenStillValid(token);
  return stillValid;
};

export const isTokenStillValid = token => {
  if (!token) {
    return false;
  }
  try {
    const decoded = jwt_decode(token);
    if (!decoded || !decoded.exp) {
      return false;
    }
    const exp = decoded.exp * 1000;
    if (exp < Date.now()) {
      return false;
    } else if (exp > Date.now()) {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export const getPathnameAfterLogin = firstLogin => {
  if (firstLogin) {
    return DEFAULT_PRIVATE_VIEW;
  }
  // TODO: if profile is not verified yet, always redirect to say profile?
  const redirectAfterLogin = localStorage.getItem(
    REDIRECT_AFTER_LOGIN_LOCALSTORAGE_KEY
  );
  const pathname = redirectAfterLogin || DEFAULT_PRIVATE_VIEW;
  localStorage.removeItem(REDIRECT_AFTER_LOGIN_LOCALSTORAGE_KEY);
  return pathname;
};

export const logout = () => {
  localStorage.removeItem("token");
};

export const ratePassword = password => {
  const feedbackMapping = {
    2: "That's an okay password...",
    3: "That's a fine password.",
    4: "That's a very strong password!",
  }
  const result = zxcvbn(password);
  const score = result.score;
  let feedback = result.feedback.warning || "please choose a safer password";
  feedback = score < MIN_PASSWORD_SCORE ? feedback : feedbackMapping[score];
  return { score, feedback };
};
