// public routes
export const ABOUT_URL = "/about";
export const ABOUT_MENU_TITLE = "About";

export const ACCEPT_INVITATION_URL = "/accept-invitation";

export const ACTIVATE_URL = "/activate";

export const CONTACT_URL = "/contact";
export const CONTACT_MENU_TITLE = "Contact";

export const CONFIRM_NEW_EMAIL_URL = "/confirm-new-email";
export const CONFIRM_NEW_EMAIL_TITLE = "Confirm new email";

export const HOME_URL = "/";
export const HOME_MENU_TITLE = "Start";

export const HOWTO_URL = "/how-it-works";
export const HOWTO_MENU_TITLE = "How It Works";

export const IMPRINT_URL = "/imprint";
export const IMPRINT_MENU_TITLE = "Imprint";

export const LOGIN_URL = "/login";
export const LOGIN_MENU_TITLE = "Log in";

export const LOGOUT_URL = "/logout";
export const LOGOUT_MENU_TITLE = "Log out";

export const OAUTH_URL = "/oauth";

export const PASSWORD_RESET_URL = "/password-reset";
export const PASSWORD_RESET_MENU_TITLE = "Password reset";

export const PASSWORD_RESET_SUCCESS_URL = "/password-reset/success";

export const PASSWORD_RESET_CONFIRM_URL = "/password-reset-confirm";

export const PRICING_URL = "/pricing";
export const PRICING_MENU_TITLE = "Pricing";

export const PRIVACY_URL = "/privacy";
export const PRIVACY_MENU_TITLE = "Privacy";

export const REGISTER_URL = "/register";
export const REGISTER_MENU_TITLE = "Register";

export const REGISTER_SUCCESS_URL = "/register/success";

export const REMOVE_ACCOUNT_URL = "/remove-account";

export const TERMS_URL = "/terms";
export const TERMS_MENU_TITLE = "Terms";

// private routes

export const ACHIEVEMENTS_URL = "/achievements";
export const ACHIEVEMENTS_MENU_TITLE = "Your achievements";

export const PROFILE_URL = "/profile";
export const PROFILE_MENU_TITLE = "Your profile";

export const BILLING_URL = "/billing";
export const BILLING_MENU_TITLE = "Billing";

export const PROFILE_EDIT_URL = "/profile/edit";
export const PROFILE_EDIT_MENU_TITLE = "Edit Profile";

export const INBOX_URL = "/inbox";
export const INBOX_MENU_TITLE = "Inbox";

export const INBOX_DETAIL_URL = "/inbox/:uuid";

// export const INVOICE_URL = "/invoice";
// export const INVOICE_MENU_TITLE = "Invoice";

export const NETWORK_URL = "/network";
export const NETWORK_MENU_TITLE = "Network";

export const NEW_REQUEST_URL = "/new-request";
export const NEW_REQUEST_MENU_TITLE = "New Post";

export const OUTBOX_URL = "/outbox";
export const OUTBOX_MENU_TITLE = "Outbox";

export const OUTBOX_DETAIL_URL = "/outbox/:uuid";
export const OUTBOX_DETAIL_PROFILE_URL = "/outbox/:questionUuid/:requestUuid";

export const SETTINGS_URL = "/settings";
export const SETTINGS_MENU_TITLE = "Your settings";
