import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import { unregister } from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import "react-app-polyfill/ie11";
import "semantic-ui-offline/semantic.min.css";

import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { withClientState } from "apollo-link-state";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";

import {
  GRAPHQL_API_URL_DEVELOPMENT,
  GRAPHQL_API_URL_PRODUCTION,
  JWT_TOKEN_KEY,
} from "./constants";

import { defaults, resolvers } from "./state";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://9578faeec4f54dec9d5ca7ca4ad80306@sentry.io/1194363",
  });
}

const URI =
  process.env.NODE_ENV === "production"
    ? GRAPHQL_API_URL_PRODUCTION
    : GRAPHQL_API_URL_DEVELOPMENT;

const cache = new InMemoryCache();

const stateLink = withClientState({
  cache,
  defaults,
  resolvers,
});

const uploadLink = createUploadLink({
  uri: URI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(JWT_TOKEN_KEY);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([stateLink, authLink, uploadLink]),
  cache,
});

const Root = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// SERVICE WORKER

unregister();
