import { isAuthenticated } from "./../helpers/auth";

export const defaults = {
  isAuthenticated: isAuthenticated(),
  showOnboardingModal: false,
  showUpdateModal: false,
  showUpgradeHintModal: false,
  showProfileVerificationPendingModal: false,
  showEmailVerificationModal: false,
  showNewPostPopup: false,
  upgradeHintText: ""
};

export const resolvers = {
  Mutation: {
    toggleAuthenticated: (_, { authenticated }, { cache }) => {
      cache.writeData({ data: { isAuthenticated: authenticated } });
      return null;
    },
    toggleOnboardingModal: (_, { visible }, { cache }) => {
      cache.writeData({ data: { showOnboardingModal: visible } });
      return null;
    },
    toggleUpdateModal: (_, { visible }, { cache }) => {
      cache.writeData({ data: { showUpdateModal: visible } });
      return null;
    },
    toggleProfileVerificationModal: (_, { visible }, { cache }) => {
      cache.writeData({
        data: { showProfileVerificationPendingModal: visible }
      });
      return null;
    },
    toggleUpgradeHintModal: (_, { visible, hintText }, { cache }) => {
      cache.writeData({
        data: { showUpgradeHintModal: visible, upgradeHintText: hintText || "" }
      });
      return null;
    },
    toggleNewPostPopup: (_, { visible }, { cache }) => {
      cache.writeData({ data: { showNewPostPopup: visible } });
      return null;
    },
    toggleEmailVerificationModal: (_, { visible }, { cache }) => {
      cache.writeData({ data: { showEmailVerificationModal: visible } });
      return null;
    }
  }
};
